<template>
  <b-card>
    <b-card-body id="print">
      <div class="form-1">
        <div class="label">
          <h6>Name</h6>
          <h6>Business Title</h6>
          <h6>Job Profile</h6>
          <h6>Location</h6>
          <h6>Manager Name</h6>
          <h6>Years of Service</h6>
        </div>
        <div class="text">
          <h6 class="bold">{{ userData.name }}</h6>
          <h6>{{ userData.team.name }}</h6>
          <h6>
            {{ userData.job_level.name }} {{ userData.department.name }},
            {{ userData.team.name }}
          </h6>
          <h6>{{ userData.branch.name }}</h6>
          <h6>{{ userData.manager.name }}</h6>
          <input type="text" class="yos-form" />
        </div>
        <div>
          <b-img
            thumbnail
            :src="userData.profile_photo_path"
            alt="profile"
            v-bind="imgOption"
          />
        </div>
      </div>

      <div class="form-2">
        <div class="f-one">
          <div class="f-group">
            <label for="retention">Retention</label>
          </div>
          <div class="f-group">
            <label for="loss_impact">Loss Impact</label>
          </div>
          <div class="f-group">
            <label for="potential">Potential</label>
          </div>
        </div>
        <div class="f-two">
          <select name="retention" id="retention">
            <option value="">Choose Retention</option>
            <option value="1">Low Risk of Leaving</option>
            <option value="2">Medium Risk of Leaving</option>
            <option value="3">High Risk of Leaving</option>
          </select>
          <select name="loss_impact" id="loss_impact">
            <option value="">Choose Loss Impact</option>
            <option value="1">Not Significant</option>
            <option value="2">Significant</option>
          </select>
          <select name="potential" id="potential">
            <option value="">Choose Potential</option>
            <option value="1">Promotable</option>
            <option value="2">Not Promotable</option>
          </select>
        </div>
        <div class="f-three">
          <div class="f-group">
            <label for="retention">Last Completed Rating</label>
          </div>
          <div class="f-four">
            <label for="loss_impact">In Progress Review</label>
          </div>
          <div class="f-group">
            <label for="potential">
              9 Box reflects average of completed ratings only
            </label>
          </div>
        </div>
        <div class="f-four">
          <h6>{{ lastCompletedRating }}</h6>
          <h6>{{ lastProgressReview }}</h6>
        </div>
        <div class="f-five">
          <div class="col twelve whole">
            <div class="flex-container">
              <div
                class="flex-item"
                id="flex1"
                @click="setActiveBox($event)"
              ></div>

              <div
                class="flex-item"
                id="flex2"
                @click="setActiveBox($event)"
              ></div>
              <div
                class="flex-item"
                id="flex3"
                @click="setActiveBox($event)"
              ></div>
            </div>
            <div class="flex-container">
              <div
                class="flex-item"
                id="flex4"
                @click="setActiveBox($event)"
              ></div>
              <div
                class="flex-item"
                id="flex5"
                @click="setActiveBox($event)"
              ></div>
              <div
                class="flex-item"
                id="flex6"
                @click="setActiveBox($event)"
              ></div>
            </div>
            <div class="flex-container">
              <div
                class="flex-item"
                id="flex7"
                @click="setActiveBox($event)"
              ></div>
              <div
                class="flex-item"
                id="flex8"
                @click="setActiveBox($event)"
              ></div>
              <div
                class="flex-item"
                id="flex9"
                @click="setActiveBox($event)"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-3">
        <label for="job_interest">Job Interest</label>
        <select name="job_interest" id="job_interest">
          <option value="">Choose Job Interest</option>
          <option
            v-for="job_interest in job_interests"
            :key="job_interest.id"
            :value="job_interest.id"
          >
            {{ job_interest.name }}
          </option>
        </select>
      </div>

      <div class="form-4">
        <div class="f-group">
          <label for="career_experience">Career Experience</label>
          <select name="career_experience" id="career_experience">
            <option value="">Choose Career Experience</option>
            <option
              v-for="exp in userData.career_experience"
              :key="exp.id"
              :value="exp.job_title"
            >
              {{ exp.job_title }}
            </option>
          </select>
        </div>
        <div class="f-group">
          <label for="experience_level">Experience Level</label>
          <select name="experience_level" id="experience_level">
            <option value="">Choose Experience Level</option>
            <option value="2">2+</option>
            <option value="5">5+</option>
            <option value="10">10+</option>
          </select>
        </div>
      </div>

      <div class="form-5">
        <table>
          <tr>
            <th>Job Profile History</th>
            <th>Business Job Title</th>
            <th>Start</th>
            <th>End</th>
          </tr>
          <tr v-for="job in userData.career_experience" :key="job.id">
            <td>{{ job.job_title }}</td>
            <td>{{ job.job_title }}</td>
            <td>{{ job.start_date }}</td>
            <td>{{ job.end_date != null ? job.end_date : "-" }}</td>
          </tr>
        </table>
      </div>

      <div class="form-6">
        <table>
          <tr>
            <th>Job History</th>
            <th>Company</th>
            <th>Start</th>
            <th>End</th>
          </tr>
          <tr v-for="job in userData.career_experience" :key="job.id">
            <td>{{ job.job_title }}</td>
            <td>{{ job.company_name }}</td>
            <td>{{ job.start_date }}</td>
            <td>{{ job.end_date != null ? job.end_date : "-" }}</td>
          </tr>
        </table>
      </div>

      <div class="form-7">
        <table>
          <tr>
            <th>Degree</th>
            <th>School</th>
            <th>Field of Study</th>
            <th>Received</th>
          </tr>
          <tr v-for="education in userData.education" :key="education.id">
            <td>{{ education.degree }}</td>
            <td>{{ education.institution_name }}</td>
            <td>{{ education.field_of_study }}</td>
            <td>{{ education.end_date }}</td>
          </tr>
        </table>
      </div>

      <div class="form-8">
        <div class="label">
          <h6>Relocation Long Term</h6>
          <h6>Relocation Short Term</h6>
          <h6>Willing to Travel</h6>
          <h6>Language</h6>
        </div>
        <div class="text">
          <select name="relong" id="relong">
            <option value="">Choose Option</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <select name="reshort" id="reshort">
            <option value="">Choose Option</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <select name="willing_to_travel" id="willing_to_travel">
            <option value="">Choose Option</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <select name="language" id="language">
            <option value="">Choose Language</option>
            <option value="id">Indonesia</option>
            <option value="en">English</option>
          </select>
        </div>
        <div class="text">
          <select name="reshort" id="reshort">
            <option value="">Choose Option</option>
            <option value="1">Asia Pacific</option>
          </select>
          <select name="willing_to_travel" id="willing_to_travel">
            <option value="">Choose Option</option>
            <option value="1">Asia Pacific</option>
          </select>
          <select name="willing_to_travel" id="willing_to_travel">
            <option value="">Choose Option</option>
            <option value="1">20%</option>
            <option value="0">50%</option>
            <option value="0">100%</option>
          </select>
        </div>
      </div>
    </b-card-body>

    <div class="text-right mt-3">
      <b-button type="submit" variant="primary" size="sm" @click="print()">
        <feather-icon icon="FileIcon" /> Export as PDF
      </b-button>
    </div>
  </b-card>
</template>

<style>
.form-1 {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  grid-template-areas: "a b c";
  border: 2px solid #004080;
  padding: 1rem;
  margin-bottom: 1rem;
}

.form-1 .label h6 {
  color: #004080;
  font-weight: 600;
}

.form-1 .yos-form {
  width: 10%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0.1rem 0.5rem;
}

.form-2 {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "a b c d e";
  border: 2px solid #004080;
  padding: 1rem;
}

.form-2 .f-one label {
  color: #004080;
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-2 .f-two select {
  border: 1px solid #e0e0e0;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 0.1rem 0.5rem;
  width: 90%;
}

.form-2 .f-three label {
  color: #004080;
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-2 .f-four h6 {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.flex-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  height: 33.33%;
}

.flex-item {
  width: 33.33%;
  height: 100%;
  text-align: center;
}

#flex1 {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex1:hover {
  background: #004080;
}

#flex2 {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex2:hover {
  background: #004080;
}

#flex3 {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex3:hover {
  background: #004080;
}

#flex4 {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex4:hover {
  background: #004080;
}

#flex5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex5:hover {
  background: #004080;
}

#flex6 {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex6:hover {
  background: #004080;
}

#flex7 {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex7:hover {
  background: #004080;
}

#flex8 {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex8:hover {
  background: #004080;
}

#flex9 {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex9:hover {
  background: #004080;
}

.col {
  min-height: 40px;
  margin: 0px;
  display: inline-block;
  float: left;
}

.col:first-child {
  margin: 0px;
}

.col:last-child {
  margin-right: 0px;
}

.col.one {
  width: 3.93%;
  height: 100%;
  background-color: black;
}

.col.two {
  width: 12.66%;
}

.col.three {
  width: 21.4%;
  height: 100%;
  background-color: black;
}

.col.four {
  width: 30.13%;
}

.col.five {
  width: 38.867%;
}

.col.six {
  width: 50%;
  height: 100%;
}

.col.seven {
  width: 57.2%;
  height: 100%;
}

.col.eight {
  width: 65.07%;
}

.col.nine {
  width: 73.8%;
}

.col.ten {
  width: 82.533%;
}

.col.eleven {
  width: 91.27%;
  border: 1px solid black;
  margin: 0 auto;
}

.col.twelve {
  width: 100%;
  height: 33%;
}
.col.twelve.whole {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .col {
    width: 100% !important;
    margin: 0px;
  }
}

.form-3 label {
  color: #004080;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  margin-top: 1rem;
}

.form-3 select {
  border: 2px solid #004080;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 0.1rem 0.5rem;
  width: 100%;
}

.form-4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "a b";
  gap: 1rem;
}

.form-4 .f-group label {
  color: #004080;
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.form-4 .f-group select {
  border: 2px solid #004080;
  background: #fff;
  margin-top: 5px;
  border-radius: 4px;
  padding: 0.1rem 0.5rem;
  width: 100%;
}

.form-5 {
  color: #004080;
  text-align: center;
  width: 100%;
}

.form-5 table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0px;
}

.form-5 table th {
  background: #004080;
  color: #fff;
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 600;
}

.form-5 table td {
  padding: 0.5rem;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  border: 1px solid #004080;
}

.form-6 {
  color: #004080;
  text-align: center;
  width: 100%;
}

.form-6 table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0px;
}

.form-6 table th {
  background: #004080;
  color: #fff;
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 600;
}

.form-6 table td {
  padding: 0.5rem;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  border: 1px solid #004080;
}

.form-7 {
  color: #004080;
  text-align: center;
  width: 100%;
}

.form-7 table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0px;
}

.form-7 table th {
  background: #004080;
  color: #fff;
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 600;
}

.form-7 table td {
  padding: 0.5rem;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  border: 1px solid #004080;
}

.form-8 {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 6fr;
  grid-template-rows: 1fr;
  grid-template-areas: "a b c d";
  border: 2px solid #004080;
  padding: 1rem;
  margin-bottom: 1rem;
}

.form-8 .label h6 {
  font-weight: 600;
  font-size: 14px;
  padding: 0.3rem 0rem;
}

.form-8 h6 {
  color: #004080;
  font-weight: 600;
}

.form-8 select {
  border: 1px solid #e0e0e0;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 0.1rem 0.5rem;
  width: 90%;
}

.active-box {
  background: #004080 !important;
}
</style>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      userData: "",
      job_interests: [],
      imgOption: {
        width: 140,
        height: 140,
        class: "m1",
      },
      lastCompletedRating: "-",
      lastProgressReview: "-",
    };
  },

  mounted() {
    this.getJobInterests();
    this.getData();
    this.getFinalEvaluation();
  },

  methods: {
    getData() {
      this.callApi({
        url: "users/fetch",
        method: "GET",
        success: (res) => {
          this.userData = res.result;
        },
      });
    },

    getFinalEvaluation() {
      this.callApi({
        url: "final_evaluations/fetch",
        params: {
          user_id: this.hashid(this.getAuthId()),
        },
        success: (res) => {
          this.lastCompletedRating = res.result[0].final_manager_score;
          this.lastProgressReview =
            res.result[0].final_goal_status == 1 ? "Completed" : "Incomplete";
        },
      });
    },

    print() {
      const doc = new jsPDF("p", "mm", "a4");
      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();

      html2canvas(document.querySelector("#print")).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        position -= pageHeight;

        // Add footer text
        const footerText = "HR ACADEMY";
        const footerWidth =
          (doc.getStringUnitWidth(footerText) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const footerX = (width - footerWidth) / 2;
        const footerY = height - 10; // Adjust the Y position as needed
        doc.text(footerX, footerY, footerText);

        while (position > -((canvas.height * imgWidth) / canvas.width)) {
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          position -= pageHeight;
        }

        doc.save("Talent-Card.pdf");
      });
    },

    getJobInterests() {
      this.callApi({
        url: "teams/fetch",
        method: "GET",
        success: (res) => {
          res.result.forEach((item) => {
            this.job_interests.push(item);
          });
        },
      });
    },

    setActiveBox(event) {
      const box = event.target;
      const boxes = document.querySelectorAll(".flex-item");
      boxes.forEach((box) => {
        box.classList.remove("active-box");
      });
      box.classList.add("active-box");

      console.log(box);
    },
  },
};
</script>